import { toShortDate } from 'core/services/date';

import './AanrijdingsFormulierModal.scss';

export const AanrijdingsFormulierModal = ({
	verzekeringsNemerData,
	verzekeringsOndernemingData,
	BtwData,
}) => {
	return (
		<section className='aanrijdings-formulier-container'>
			<h2>Voorzijde</h2>
			<h3>6. Verzekeringsnemer:</h3>
			<section>
				{verzekeringsNemerData ? (
					<ul>
						<li className='label'>
							Naam :{' '}
							<span className='data'>
								{verzekeringsNemerData.naam}
							</span>
						</li>
						<li className='label'>
							Adres :{' '}
							<span className='data'>
								{verzekeringsNemerData.adres}
							</span>
						</li>
						<li className='label'>
							Postcode :{' '}
							<span className='data'>
								{verzekeringsNemerData.postcode}
							</span>{' '}
							<span className='label'>Land :</span>{' '}
							<span className='data'>
								{verzekeringsNemerData.land}
							</span>
						</li>
						<li className='label'>
							Tel. of Email :{' '}
							<span className='data'>
								{verzekeringsNemerData.telefoon} en/of{' '}
								{verzekeringsNemerData.mail}
							</span>
						</li>
					</ul>
				) : null}
			</section>
			<h3>
				8. Verzekeringsonderneming:
				<span className='info'> (zie verzekeringsattest)</span>
			</h3>
			<section>
				{verzekeringsOndernemingData ? (
					<ul>
						<li className='label'>
							Naam :{' '}
							<span className='data'>
								{verzekeringsOndernemingData.naam}
							</span>
						</li>
						<li className='label'>
							contractnummer :{' '}
							<span className='data'>
								{verzekeringsOndernemingData.contractnummer}
							</span>
						</li>
						<li className='label'>
							Nr. van groene kaart :{' '}
							<span className='data'>
								{verzekeringsOndernemingData.nummerGroeneKaart}
							</span>
						</li>
						<li className='label'>
							Verzekeringsattest of &apos;groene&apos; kaart
							geldig van{' '}
							<span>
								{verzekeringsOndernemingData.groeneKaartGeldigVan
									? `${toShortDate(verzekeringsOndernemingData.groeneKaartGeldigVan)}`
									: ` ... `}
							</span>{' '}
							tot{' '}
							<span>
								{verzekeringsOndernemingData.groeneKaartGeldigTot
									? `${toShortDate(verzekeringsOndernemingData.groeneKaartGeldigTot)}`
									: ` ... `}
							</span>
						</li>
					</ul>
				) : null}
			</section>
			<h2>Achterzijde</h2>
			<p>De achterzijde moet ingevuld worden door de aangifte.</p>
			<p>
				Dit mag je doen als je terug thuis bent. He hebt voor deze
				verklaringen geen goedkeuring nodig van de tegenpartij.
			</p>
			<h3>De B.T.W.:</h3>
			<section>
				{BtwData ? (
					<ul>
						<li className='label'>
							Beroep van de eigenaar :{' '}
							<span className='data'>
								{BtwData.beroep_eigenaar}
							</span>
						</li>
						<li className='label'>
							registratienummer :{' '}
							<span className='data'>
								{BtwData.registratieNummer}
							</span>
						</li>

						<li className='label'>
							BTW aftrekbaar :
							<span className='data'>
								{BtwData.BTW_aftrekbaar ? `Ja ` : `Nee `}
							</span>
						</li>
						{BtwData.BTW_aftrekbaar ? (
							<li className='label'>
								Zo ja :{' '}
								<span className='data'>
									{BtwData.BTW_aftrekbaar_hoeveelheid}%
								</span>
							</li>
						) : null}
					</ul>
				) : null}
			</section>
			<h3>
				Verzekeringen die voor je voortuig afgesloten werden:{' '}
				<span className='info'>mag je open laten.</span>
			</h3>

			<h3>Nummer van Post- of bankrekening:</h3>
			<ul>
				<li className='label'>
					<strong>IBAN:</strong>
					<span className='data'> BE34 2200 3700 1290</span>
				</li>
				<li className='label'>
					<strong>BIC:</strong>
					<span className='data'> GEBABEBB</span>
				</li>
			</ul>
			<h3>
				Bij vragen:<span className='info'> ehbo@unipartners.be</span>
			</h3>
		</section>
	);
};
