import { forwardRef } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useOnlineStatus } from 'core/hooks/OnlineStatusProvider';
import { useTheme } from 'core/hooks/ThemeProvider';
import {
	array,
	bool,
	func,
	node,
	number,
	object,
	oneOfType,
	string,
} from 'prop-types';

import { Image, ImageLoading } from '../Image';
import Stickers from '../Stickers/Stickers';

import CardBody from './Body/CardBody';
import CardFooter from './CardFooter/CardFooter';
import CardHeader from './CardHeader/CardHeader';

import 'react-loading-skeleton/dist/skeleton.css';
import './Card.scss';

const Card = forwardRef(
	(
		{
			title,
			subTitle,
			url,
			meta,
			icon,
			picture,
			showPlaceholder = true,
			addStickers = false,
			stickers = [],
			addComments = false,
			roundedPicture = false,
			shadowPicture = false,
			footer,
			children,
			withShadow = false,
			useFunc,
			className,
			loading = false,
			stickerType,
			objId,
			objType,
			feedType,
			skeletonCount = 1,
			extraFooterClass,
			hasOverflow,
			readMoreText,
			fullSizeLink = false,
		},
		ref,
	) => {
		const online = useOnlineStatus();
		const { theme } = useTheme();
		const navigate = useNavigate();

		const handleCardClick = () => {
			if (fullSizeLink && url) {
				navigate(url);
			}
		};

		return (
			<SkeletonTheme
				baseColor={theme === 'dark' ? '#1A1217' : '#FAFAFA'}
				highlightColor={
					theme === 'dark'
						? 'rgba(65, 47, 59, .4)'
						: 'rgba(131, 94, 130, .2)'
				}>
				<div
					className={`card${withShadow ? ' shadow' : ''}${
						addStickers && !addComments ? ' with-stickers' : ''
					}${hasOverflow ? ' with-read-more' : ''}${
						picture ? ' with-picture' : ''
					}${loading || picture ? '' : ' without-picture'}${
						className ? ` ${className}` : ''
					}${fullSizeLink ? ' full-size-link' : ''}`}
					onClick={handleCardClick}
					ref={ref}>
					<div className='card__content'>
						{loading ? (
							<ImageLoading
								rounded={roundedPicture}
								shadow={shadowPicture}
							/>
						) : (
							<Image
								id={picture}
								objType={objType}
								alt={title}
								rounded={roundedPicture}
								shadow={shadowPicture}
								showPlaceholder={showPlaceholder}
							/>
						)}
						{icon ? (
							<div className='card__content__background'>
								<FontAwesomeIcon icon={icon} fixedWidth />
							</div>
						) : null}

						<div className='card__content_wrapper'>
							{title || subTitle || meta ? (
								<CardHeader
									title={title}
									subTitle={subTitle}
									url={url ? url : null}
									meta={meta}
									showArrow={useFunc === undefined}
									loading={loading}
								/>
							) : null}

							<CardBody isFolded={false}>
								{!loading ? (
									children
								) : online ? (
									<Skeleton count={skeletonCount} />
								) : (
									'Item kan niet worden geladen.'
								)}
							</CardBody>

							{hasOverflow ? (
								url ? (
									<Link to={url} className='card__read-more'>
										{readMoreText ?? 'Lees meer'}
									</Link>
								) : (
									<div className='card__read-more'>
										{readMoreText ?? 'Lees meer'}
									</div>
								)
							) : null}
						</div>
					</div>

					{footer ? (
						<CardFooter className={extraFooterClass}>
							{footer}
						</CardFooter>
					) : null}

					{addStickers ? (
						<Stickers
							type={stickerType}
							objId={objId}
							feedType={feedType}
							stickers={stickers}
							addButton
						/>
					) : null}
				</div>
			</SkeletonTheme>
		);
	},
);

Card.displayName = 'Card';

Card.propTypes = {
	title: string,
	subTitle: string,
	url: string,
	meta: string,
	icon: object,
	picture: oneOfType([string, object]),
	pictureType: string,
	shadowPicture: bool,
	roundedPicture: bool,
	footer: object,
	children: node,
	withShadow: bool,
	useFunc: func,
	readMoreText: string,
	className: string,
	loading: bool,
	skeletonCount: number,
	addStickers: bool,
	addComments: bool,
	stickers: array,
	objId: number,
	objType: string,
	feedType: string,
	stickerType: string,
	hasOverflow: bool,
	fullSizeLink: bool,
};

export default Card;
