import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { loadTraining } from 'core/store/trainings/trainings.actions';

import { DetailPageLayout } from 'components/Layouts/DetailPageLayout/DetailPageLayout';
import Loading from 'components/Loading/Loading';

import TrainingFormModal from '../TrainingForm/TrainingFormModal';

import './TrainingDetail.scss';

const TrainingDetail = () => {
	const { slug } = useParams();

	const [modal, setModal] = useState(null);
	const { trainingDetails: training } = useSelector(
		state => state.trainingReducer,
	);

	useEffect(() => {
		loadTraining(slug);
	}, [slug]);

	return training ? (
		<>
			<DetailPageLayout
				id={training.id}
				itemToShow={training}
				title={training.name}
				feedDetail={training}
				creator={`${training.creator.firstName} ${training.creator.lastName}`}
				createdAt={training.publishedAt}
				slug='opleidingen'
				days={training.days}
				organisors={training.organisers}
				location={training.location}
				lastDay={training.maxAttendDate}
				maxAttendees={training.maxAttendees}
				numberOfAttendees={training.numberOfAttendees}
				invitees={training.invitees.filter(x => x.confirmed)}
				type='trainings'
				showForm={setModal}
			/>
			{modal ? (
				<TrainingFormModal
					modal={modal}
					training={training}
					setModal={setModal}
				/>
			) : null}
		</>
	) : (
		<Loading />
	);
};

export default TrainingDetail;
